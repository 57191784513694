import { uuid } from 'vue-uuid'

import ConstUtils from '@/utils/ConstUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import EventUtils from '@/utils/EventUtils.js';

import MC from "@/domain/session/MC.js";
import WebLogger from "@/domain/logger/WebLogger.js";
import RcDomain from '@/domain/RcDomain.js';
import UserEvents from '@/domain/model/user/UserEvents.js';
import Company from '@/domain/model/company/Company.js';
import EventsDomain from '@/domain/model/events/EventsDomain.js';

export default class ConnectionUtils {

  static ensureConnection(page) {
    page.isLoading = true;
    var mc = new MC();
    page.loadingMessage = mc.Status.Loading.value();
    if (!ConnectionUtils.isConnected(page, page.callback)) {
      ConnectionUtils.showSignIn(page);
    }
  }
  
  static createDomainSession(page) {
    ConnectionUtils.createDomain(page, page.callback)
    ConnectionUtils.createSession(page);
    if (page.start) {
      page.start();
    }
  }
  
  static displayStatus(page) {
    if (!page.auth_connected) {
      page.showSessionTimeoutDialog = true;
    } else {
      ConnectionUtils.status(page.auth_socket_status, page.callback);
    }
  }
  
  static sendSignInEvent(page) {
    if (!page.auth_connected) {
      page.showSessionTimeoutDialog = true;
    } else {
      ConnectionUtils.createConnection(page, page.callback);
    }
  }
  
  static withAdminCompany(page) {
    var company = new Company(page.domain);
    company.withName("Admin");
    page.domain.session().with(company);
    page.domain.session().companyName = null;
    page.AuthStorage_updateCompany(company);
  }
  
  static showSignIn(page) {
    page.$router.replace(
        { name: page.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN });
  }
  
  static isConnected(page, callback) {
    var mc = new MC();
    var logger = new WebLogger(window.console, true);
    if (page) {
      if (page.auth_connected) {
        logger.info("Already connected: auth_connected starting page");
        page.start();
        return true;
      } else {
        logger.info("Not connected: reading cookie");
        var value = window.localStorage.readyChek;
        if (!value) {
          return false;
        }
        var cookieData = JSON.parse(value);
        if (!cookieData) {
          return false
        }
        
        if (callback) {
          callback(mc.Status.Connecting.value());
        }
        logger.info("Attempting to create connection");
        page.$connect();
      }
    }
    return true;
  }
  
  static createConnection(page, callback) {
    var mc = new MC();
    var logger = new WebLogger(window.console, true);
    if (page) {
      //
    }
    if (callback) {
      callback(mc.Status.Connecting.value());
    }
    
    var value = window.localStorage.readyChek;
    var cookieData = {};
    if (value) {
      cookieData = JSON.parse(value);
    } else {
      cookieData['email'] = page.email;
      cookieData['password'] = page.password;
    }
    
    logger.info("Sending signin event");
    
    const requestEvent = UserEvents.SignIn.Request(cookieData.email, cookieData.password);
    requestEvent[ConstUtils.GRECAPTCHA.NAME] = '';
    requestEvent['maker'] = window.navigator.userAgent;
    EventsDomain.initEvent(
        requestEvent, null, 
        uuid.v1(), 
        page.auth_client.deviceId, 
        page.auth_client.version);
    EventUtils.Send.event(page.$socket, requestEvent);
  }
  
  static createDomain(page, callback) {
    var mc = new MC();
    var logger = new WebLogger(window.console, true);
    callback(mc.Status.CreatingSession.value());
    var created = false;
    logger.info("Creating domain");
    var domain = new RcDomain(page.auth_client);
    domain
      .withStorage(page.$store.getters)
      .withLogger(window.console)
      .start();
    domain
      .events()
      .withListeners(page.addListenerAction, page.removeListenerAction);
    created = true;
    if (page.updateDomain) {
      page.updateDomain(domain);
    }
    
    callback("Done");
    return created;
  }
  
  static createSession(page) {
    var domain = page.domain;
    var session = domain.session();
    var user = session.user();

    var employees = domain.employees().findByUserId(user.id());
    var logger = new WebLogger(window.console, true);
    var value = window.localStorage.readyChek;
    var cookieData = {};
    
    if (value) {
      cookieData = JSON.parse(value);
    }
    var companyId = cookieData['Company'];
    if (StringUtils.isEmpty(companyId)) {
      page.$router.replace({ name: page.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN });
      return session;
    }
    var employee = employees.findByCompanyId(companyId);
    if (employees.length() > 1 && employee.isNew()) {
      page.$router.replace({ name: page.$route.query.redirectTo || ConstUtils.ROUTES.COMPANY.SELECT });
    } else {
      var company = employee.company().find();
      logger.info("User: {0}", [employee.user().find().fullName()]);
      logger.info("Employee for company: {0}", [company.name()]);
      session.with(company);
      session.with(employee);
      if (page.AuthStorage_updateCompany) {
        page.AuthStorage_updateCompany(company);
      }
    }
    return session;
  }
  
  static status(status, callback) {
    var mc = new MC();
    if (!status) {
      callback(mc.Status.Loading.value());
      
    } else {
      if (status == 'findingYou') {
        callback(mc.Status.FindingYou.value());
      } else if (status == 'findingSchedules') {
        callback(mc.Status.FindingSchedules.value());
      } else if (status == 'findingInspections') {
        callback(mc.Status.FindingInspections.value());
      } else if (status == 'findingTimesheets') {
        callback(mc.Status.FindingHoursOfService.value());
      } else {
        callback(mc.Status.StoringData.value())
      }
    }
  }
  
  static adminInit(page) {
    page.isLoading = true;
    if (page.domain) {
      var domain = page.domain;
      var session = domain.session();
      session.companyName = null;
      session.withoutCompany();
    }  
  }
  
  static loadCompany(page) {
    var mc = new MC();
    var context = new ConnectionContext(page)
    
    var company = page.domain.session().company();
    var fetch = company.id() != page.companyId;
    if (company.isPointer()) {
      fetch = true;
    }
    
    if (!page.companyId || !page.companyId.startsWith("#")) {
      fetch = false;
    }
  
    if (fetch) {
      page.isLoading = true;
      page.domain.session().companyName = null;
      page.loadingMessage = mc.Company.Status.Loading.value();
      var event = page.domain.events().company().details(page.companyId);
      event.send(context.loadCompanyListener, context);
    } else {
      context.done();
    }
  }
  
}

export class ConnectionContext {
  
  constructor(_page) {
    this.page = _page;
  }
  
  loadCompanyListener(response, context) {
    var page = context.page;
    var payload = response.payload();
    var data = payload.valuesFor(Company.MODEL_NAME);
    var company = new Company(page.domain, data);
    
    page.domain.session().with(company);
    page.domain.session().companyName = company.name();
    page.AuthStorage_updateCompany(company);
    context.done();
  }
  
  done() {  
    this.page.isLoading = false;
    if (this.page.startCompany) {
      this.page.startCompany();
    }
  }
}

