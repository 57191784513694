
import WebLogger from '@/domain/logger/WebLogger.js';

import CategorysDomain from '@/domain/model/category/CategorysDomain.js';
import FeaturesDomain from '@/domain/model/feature/FeaturesDomain.js';
import MakersDomain from '@/domain/model/MakersDomain.js';
import NotesDomain from '@/domain/model/schedule/question/option/note/NotesDomain.js';
import OptionsDomain from '@/domain/model/schedule/question/option/OptionsDomain.js';
import QuestionsDomain from '@/domain/model/schedule/question/QuestionsDomain.js';
import RepairResultsDomain from '@/domain/model/inspection/repair/result/RepairResultsDomain.js';
import RepairsDomain from '@/domain/model/answer/repair/RepairsDomain.js';
import SchedulesDomain from '@/domain/model/schedule/SchedulesDomain.js';
import SignaturesDomain from '@/domain/model/user/SignaturesDomain.js';

import TypesDomain from '@/domain/model/TypesDomain.js';
import UsersDomain from '@/domain/model/user/UsersDomain.js';
import RolesDomain from '@/domain/model/role/RolesDomain.js';

import ImagesDomain from '@/domain/model/image/ImagesDomain.js';

import AppStatsDomain from '@/domain/model/app/AppStatsDomain.js';
import AddressesDomain from '@/domain/model/address/AddressesDomain.js';
import AnswersDomain from '@/domain/model/answer/AnswersDomain.js';
import AssetsDomain from '@/domain/model/asset/AssetsDomain.js';
import CompanysDomain from '@/domain/model/company/CompanysDomain.js';
import CommentsDomain from '@/domain/model/comment/CommentsDomain.js';
import EventsDomain from '@/domain/model/events/EventsDomain.js';
import EmployeesDomain from '@/domain/model/employee/EmployeesDomain.js';
import InspectionsDomain from '@/domain/model/inspection/InspectionsDomain.js';
import ResultsDomain from '@/domain/model/inspection/result/ResultsDomain.js';
import VehiclesDomain from '@/domain/model/vehicle/VehiclesDomain.js';

import InvoicesDomain from '@/domain/model/invoice/InvoicesDomain.js';
import TimesheetsDomain from '@/domain/model/timesheet/TimesheetsDomain.js';

import CheckInsDomain from '@/domain/model/checkin/CheckInsDomain.js';
import CheckListsDomain from '@/domain/model/checklist/CheckListsDomain.js';

import RcSession from './session/RcSession.js';
import RcObject from './common/RcObject.js';
import RcTime from "./session/time/RcTime.js";

import CanadianEnglish from "@/domain/session/CanadianEnglish.js"

export default class RcDomain extends RcObject {

  constructor(client) {
    super();
    this._client = client;
    this._session = new RcSession(this, this._client.user);
    this._logger = {
        info: function() {
          
        }
    };
    this._time = new RcTime();
  }

  start() {
    this._session.start(this._logger);
    this._time = new RcTime();
    return this;
  }

  withLogger(console) {
    this._logger = new WebLogger(console, this._client.debug);
    return this;
  }

  withStorage(storeMap) {
    this._categorys = new CategorysDomain(this, storeMap.categorys_found);
    this._companys = new CompanysDomain(this, storeMap);
    this._employees = new EmployeesDomain(this, storeMap.employees_found);
    this._features = new FeaturesDomain(this, storeMap.features_store);
    this._inspections = new InspectionsDomain(this, storeMap.inspections_found);
    this._results = new ResultsDomain(this, storeMap.ResultStorage);
    this._makers = new MakersDomain(this, storeMap.makers_found);
    this._options = new OptionsDomain(this, storeMap.options_found);
    this._notes = new NotesDomain(this, storeMap.NoteStorage);
    this._questions = new QuestionsDomain(this, storeMap.questions_found);
    this._repairResults = new RepairResultsDomain(this, storeMap.repairResults_store);
    this._repairs = new RepairsDomain(this, storeMap.repairs_found);
    this._schedules = new SchedulesDomain(this, storeMap.schedules_found);
    this._sigantures = new SignaturesDomain(this, storeMap.signatures_store);
    this._types = new TypesDomain(this, storeMap.types_found);
    this._roles = new RolesDomain(this, storeMap.roles_found);
    this._users = new UsersDomain(this, storeMap.users_store, storeMap.signatures_store);
    this._vehicles = new VehiclesDomain(this, storeMap.vehicles_found);
    this._events = new EventsDomain(this, storeMap.events_store);
    
    this._appStats = new AppStatsDomain(this, storeMap.AppStatsStorage);
    
    this._addresses = new AddressesDomain(this, storeMap.AddressStorage);
    this._answers = new AnswersDomain(this, storeMap.AnswerStorage);
    this._assets = new AssetsDomain(this, storeMap.AssetStorage);
    this._comments = new CommentsDomain(this, storeMap.CommentStorage);
    this._invoices = new InvoicesDomain(this, storeMap.InvoiceStorage, storeMap.InvoiceSettingsStorage, storeMap.CardStorage);
    this._timesheets = new TimesheetsDomain(this, storeMap.TimesheetStorage, storeMap.TimesheetEntryStorage);
    this._checkins = new CheckInsDomain(this, storeMap.CheckInStorage);
    this._checklists = new CheckListsDomain(this, storeMap);
    this._images = new ImagesDomain(this, storeMap);
    return this;
  }

  time() {
    return this._time;
  }
  
  appStats() {
    return this._appStats;
  }
  
  checklists() {
    return this._checklists;
  }
  
  logger() {
    return this._logger;
  }

  client() {
    return this._client;
  }
  
  results() {
    return this._results;
  }
  
  images() {
    return this._images;
  }
  
  timesheets() {
    return this._timesheets;
  }
  
  checkins() {
    return this._checkins;
  }
  
  comments() {
    return this._comments;
  }
  
  invoices() {
    return this._invoices;
  }
  
  events() {
    return this._events;
  }
  
  roles(){
    return this._roles;
  }

  assets() {
    return this._assets;
  }
  
  addresses() {
    return this._addresses;
  }
  
  answers() {
    return this._answers;
  }

  categorys() {
    return this._categorys;
  }

  companys() {
    return this._companys;
  }

  employees() {
    return this._employees;
  }

  features() {
    return this._features;
  }

  inspections() {
    return this._inspections;
  }

  makers() {
    return this._makers;
  }
  
  options() {
    return this._options;
  }
  
  notes() {
    return this._notes;
  }
  
  questions() {
    return this._questions;
  }

  repairResults() {
    return this._repairResults;
  }

  repairs() {
    return this._repairs;
  }

  schedules() {
    return this._schedules;
  }

  signatures() {
    return this._sigantures;
  }

  types() {
    return this._types;
  }

  users() {
    return this._users;
  }

  vehicles() {
    return this._vehicles;
  }

  session() {
    return this._session;
  }

  lang() {
    if (this._session) {
      return this._session.lang();
    }
    
    return new CanadianEnglish();
  }
}