
import RcDomainObject from "@/domain/common/RcDomainObject";
import Invoice from "./Invoice.js";
import InvoiceSettingsDomain from "./InvoiceSettingsDomain.js"
import CardsDomain from "./CardsDomain.js"

export default class InvoicesDomain extends RcDomainObject {
  
  constructor(domain, storage, settings, cards) {
    super(domain);
    this._storage = storage;
    this._settings = new InvoiceSettingsDomain(this.domain, settings);
    this._cards = new CardsDomain(this.domain, cards);
  }
  
  list() {
    return this._storage.list;
  }
  
  findById(objId) {
    var objects = this.list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      if (super.toId(item) == objId) {
        return new Invoice(this.domain, item);
      }
    }

    return new Invoice(this.domain, {});
  }
  
  settings() {
    return this._settings;
  }
  
  cards() {
    return this._cards;
  }
}